#hero_div {
    padding: 0 15% 130px 15%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(rgba(174,185,238, 0.5),rgba(148,187,233, 0.5)), url(/assets/img/hero.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}


#hero_text_div {
    padding: 30px 0;
}

#hero_text_div h1 {
    margin: 0;
    padding: 0 0 15px 0;
    font-family: open sans;
    font-weight: 300;
    color: #e2e2e2;
    font-size: 50px;
    line-height: 50px;
    text-align: center;
}

#hero_text_div p {
    font-family: open sans;
    color: #e2e2e2;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    padding: 0;
    margin: 0;
}

#buttons_div {
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.button_main {
    background-color: #1e90ff;
    border: solid 2px #1e90ff;
}

@media only screen and (max-width: 767px) {
    #hero_text_div h1 {
        font-size: 28px;
        line-height: 30px;
    }
    
    #hero_div {
        padding: 0 5% 130px 5%;
    }

    #buttons_div {
        flex-direction: column;
    }
}