@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans:300,400);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
    text-decoration: none;
    transition: color 0.25s ease;
}

a:hover {
    color: #05a6f8;
}

body {
    margin: 0;
}

.button {
    padding: 10px 20px;
    margin: 0 30px;
    font-family: montserrat;
    color: #e6eff3;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    border: solid 2px #e6eff3;
    border-radius: 25px;
    transition: opacity 0.25s ease;
}

.button:hover {
    opacity: 0.5;
    color: #e6eff3;
}

.left {
    text-align: left !important;
}

.light {
    color: #e6eff3 !important;
}

.red {
    color: #05a6f8 !important;
}

@media only screen and (max-width: 767px) {
    .button {
        font-size: 12px;
        margin-bottom: 10px;
    }
}
#hero_div {
    padding: 0 15% 130px 15%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(rgba(174,185,238, 0.5),rgba(148,187,233, 0.5)), url(/assets/img/hero.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}


#hero_text_div {
    padding: 30px 0;
}

#hero_text_div h1 {
    margin: 0;
    padding: 0 0 15px 0;
    font-family: open sans;
    font-weight: 300;
    color: #e2e2e2;
    font-size: 50px;
    line-height: 50px;
    text-align: center;
}

#hero_text_div p {
    font-family: open sans;
    color: #e2e2e2;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    padding: 0;
    margin: 0;
}

#buttons_div {
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.button_main {
    background-color: #1e90ff;
    border: solid 2px #1e90ff;
}

@media only screen and (max-width: 767px) {
    #hero_text_div h1 {
        font-size: 28px;
        line-height: 30px;
    }
    
    #hero_div {
        padding: 0 5% 130px 5%;
    }

    #buttons_div {
        flex-direction: column;
    }
}
#footer_column_div {
    display: flex;
   padding: 50px 15%;
}

.footer_column {
   width: 33%;
   padding: 0 50px;
}

.footer_column ul {
   list-style: none;
   padding: 0;
}

.footer_column ul li {
   padding: 10px 0;
   border-top: 1px solid #c4c4c4;
}

.footer_column ul li:first-child {
   border: none;
}

.footer {
   color: #888;
   font-size: 26px;
}

.footer_sub {
    color: #888;
}

.div_subheading {
    text-align: center;
}

@media only screen and (max-width: 991px) {
    #footer_column_div {
        flex-direction: column;
    }
    
    .footer_column {
        width: auto;
    }
    
    .footer_column p {
        padding-left: 5%;
    }
    
    .footer {
        text-align: center;
    }
    
    .footer_column ul li {
        text-align: center;
    }


}

@media only screen and (max-width: 767px) {
    #footer_div {
        padding: 50px 5%;
    }
    
    #footer_column_div {
        padding: 0;
    }
    
    .footer_column {
        padding: 20px 0 0 0;
    }
}
